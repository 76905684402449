const rubric_list_tbl = [
    {
      text: 'TITLE',
      value: 'title',
      align: 'start',
      sortable: false
    },
    {
      text: 'DESCRIPTION',
      value: 'description',
      align: 'center',
      sortable: false
    },
    {
      text: 'TYPE',
      value: 'type_of_rubric',
      align: 'center',
      class: 'text-capitalize',
      sortable: false
    },
    {
      text: 'DATE CREATED',
      value: 'created_at',
      align: 'center',
      class: 'text-capitalize',
      sortable: false
    },
    {
      text: 'ACTION',
      value: 'action',
      align: 'center',
      sortable: false,
    }
]

export { rubric_list_tbl }