var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"editable f14 fw500 roboto",style:(_vm.type == 'title' ? 'min-height: 0px' : ''),attrs:{"contenteditable":_vm.edit},domProps:{"innerHTML":_vm._s(_vm.type == 'title' ? _vm.data.title : _vm.data.body)},on:{"focus":function($event){return _vm.$emit('inputFocus', {
        event: $event,
        index: _vm.action == 'update' ? _vm.data : _vm.index,
        parent: _vm.parent,
        type: _vm.type,
      })},"blur":function($event){return _vm.$emit('inputLostFocus', {
        event: $event,
        index: _vm.action == 'update' ? _vm.data : _vm.index,
        parent: _vm.parent,
        type: _vm.type,
      })}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }